import { application } from "./application"
import Flatpickr from "./flatpickr"
import StimulusTomSelect from "./tom_select";
import PlacesAutocomplete from './places_autocomplete'
import DependsOn from "./depends_on";
import CookieBar from "./cookie_bar"
import ProgressDonut from "./progress_donut"
import SweetAlert from "./sweet_alert";
import HeaderSticky from "./header_sticky";
import UnsavedForm from "./unsaved_form";
import FiscalCode from "./fiscal_code";
import ResetRadio from "./reset_radio";
import PasswordVisibility from './password_visibility';
import BootstrapTable from "./boostrap_table";
import Collapse from "./collapse";
import Notifications from "./notifications"
import Charts from "./charts"
import Swiper from "./swiper"
import Clipboard from '@stimulus-components/clipboard'

application.register("tom-select", StimulusTomSelect);
application.register("flatpickr", Flatpickr)
application.register("places", PlacesAutocomplete)
application.register("depends_on", DependsOn)
application.register('password-visibility', PasswordVisibility)
application.register("cookie-bar", CookieBar)
application.register("progress-donut", ProgressDonut)
application.register('sweet_alert', SweetAlert);
application.register('header_sticky', HeaderSticky);
application.register('unsaved_form', UnsavedForm);
application.register('fiscal-code', FiscalCode);
application.register('reset_radio', ResetRadio);
application.register('table', BootstrapTable);
application.register('collapse', Collapse);
application.register('notifications', Notifications);
application.register('charts', Charts);
application.register('swiper', Swiper);
application.register('clipboard', Clipboard);
