import { Controller } from '@hotwired/stimulus'
import * as echarts from 'echarts'
import italyMap from '../provinces.json'
echarts.registerMap('Italy', italyMap)

export default class extends Controller {
  static values = {
    type: String,
    source: String,
    title: String
  }

  static targets = ['spinner']

  initialize() {
    // const spinner = this.element.querySelector('.spinner-border');
    this.fetchData()
      .then(data => this.renderChart(data))
      .catch(error => {
        this.renderError()
      });
  }

  async fetchData() {
    const response = await fetch(this.sourceValue);
    return await response.json();
  }

  renderError() {
    // const spinner = this.element.querySelector('.spinner-border');
    let errorMessage = '<h4>' + i18n.t('charts.' + this.titleValue) + '</h4>'
    // spinner.innerHTML = errorMessage + i18n.t("error_rendering_chart")
  }

  renderChart(data) {
    // Ensure the container has a defined size
    this.element.style.width = '100%';
    this.element.style.height = '400px'; // Adjust as needed

    // Initialize chart after a short delay to ensure DOM is ready
    setTimeout(() => {
      const chart = echarts.init(this.element);
      let option = this.getChartOption(data);
      chart.setOption(option);
    }, 0);
  }

  getChartOption(data) {
    switch(this.typeValue) {
      case 'line':
        return this.getLineChartOption(data);
      case 'pie':
        return this.getPieChartOption(data);
      case 'map':
        return this.getMapChartOption(data);
      default:
        return {};
    }
  }
  
  getLineChartOption(data) {
    return {
      title: {
        text: data.chart.title,
        left: 'center'
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: data.series.map(dataset => dataset.name),
        bottom: 0
      },
      xAxis: {
        type: 'category',
        data: data.labels
      },
      yAxis: {
        type: 'value'
      },
      series: data.series.map(dataset => ({
        name: dataset.name,
        type: 'line',
        smooth: true,
        data: dataset.data
      }))
    };
  }

  getPieChartOption(data) {
    return {
      title: {
        text: data.chart.title,
        left: 'center'
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ({d}%)'
      },
      legend: {
        orient: 'horizontal',
        bottom: 0
      },
      series: [{
        name: data.chart.title,
        type: 'pie',
        radius: '50%',
        data: data.labels.map((label, index) => ({
          value: data.series[0].data[index],
          name: label
        })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }]
    };
  }
  getMapChartOption(data) {
    const mapData = data.series[0].data;
    return {
      title: {
        text: data.chart.title,
        left: 'center'
      },
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          const value = isNaN(params.value) ? 0 : params.value;
          return `${params.name}: ${value}`;
        }
      },
      visualMap: {
        min: 0,
        max: Math.max(...mapData.map(item => item.value)),
        left: 'center',
        bottom: 0,
        text: ['High', 'Low'],
        calculable: true,
        orient: 'horizontal'
      },
      series: [
        {
          name: data.series[0].name,
          type: 'map',
          map: 'Italy',
          roam: true,
          label: {
            show: false
          },
          nameProperty: 'prov_acr',
          data: mapData
        }
      ]
    };
  }

}


